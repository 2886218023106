var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"list-item list-item--playlist",attrs:{"aria-expanded":_vm.selected},on:{"click":function($event){$event.preventDefault();!_vm.selected && _vm.toggleExpanded()}}},[_c('header',{staticClass:"title justify"},[_c('h6',{staticClass:"title-text",on:{"click":function($event){$event.stopPropagation();return _vm.toggleExpanded($event)}}},[_c('span',{staticClass:"grow"},[_c('i',{class:{
            'fab fa-youtube': _vm.header || !_vm.form.isHidden,
            'fas fa-eye-slash': !_vm.header && _vm.form.isHidden,
            'grey-light--text': !_vm.selected,
            'success--text': !_vm.selected && _vm.form.isSubscriberPL,
            'error--text': _vm.selectedVideo,
            link: _vm.selected && !_vm.selectedVideo
          }}),_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('i',{staticClass:"fas scale-in-bounce",class:{ 'fa-times-circle error--text': _vm.selected },attrs:{"role":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('deselect-playlist', _vm.playlistId)}}})]),(!_vm.header && _vm.showAdminControls)?_c('library-list-item-controls',{attrs:{"form-data":_vm.formData,"playlist-id":_vm.playlistId},on:{"change":_vm.appendAndEmit}}):_vm._e()],1),(_vm.selected && _vm.form.isSubscriberPL)?_c('blockquote',{staticClass:"subscribers-stamp"},[_c('i',{staticClass:"fas fa-credit-card"}),_vm._v(" Subscribers Only ")]):_vm._e(),(_vm.selected)?_c('div',{class:{ 'slide-down-fade-in': _vm.selected, 'list--videos': true },attrs:{"role":"menu","aria-expanded":_vm.selected}},[(_vm.selectedVideo)?_c('iframe',{staticClass:"selected-video route-content",attrs:{"src":("https://www.youtube.com/embed/" + (_vm.selectedVideo.videoId)),"allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":"","frameborder":"0","height":"400","width":"100%"}}):_vm._e(),_vm._l((_vm.videosList),function(video){return _c('tile-list-item',{key:video.videoId,staticClass:"list-item--video",class:{ active: _vm.selectedVideoId === video.videoId },attrs:{"role":"menuitem","action-text":_vm.videoThumbnailText(video.videoId),"background-image":video.thumbnail,"href":video.videoLink,"selected":_vm.selectedVideoId === video.videoId,"title":video.title,"button":""},on:{"list-item-click":function($event){return _vm.selectVideo(video)}}})})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }