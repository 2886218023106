<template>
  <div class="list-item--controls list-item--label">
    <label class="admin-tools grow">
      <i
        class="fas"
        :class="{
          'fa-check-circle success--text':
            !form.isSubscriberPL && !form.isHidden,
          'fa-times-circle grey-light--text':
            form.isSubscriberPL || form.isHidden
        }"
      />
      <hr class="divider divider--vertical" />
      <em>&nbsp;All Users</em>
    </label>

    <label class="admin-tools">
      <input
        :disabled="form.isHidden"
        :name="playlistId"
        @click.stop="appendAndEmit({ isSubscriberPL: !form.isSubscriberPL })"
        class="checkbox-input"
        type="checkbox"
        v-model="form.isSubscriberPL"
      />
      <i class="far fa-credit-card grey--text"></i>&nbsp;
      <hr class="divider divider--vertical" />
      <component :is="form.isHidden ? 'em' : 'b'">Subscribers</component>
    </label>

    <label class="admin-tools">
      <input
        :name="playlistId"
        @click.stop="appendAndEmit({ isHidden: !form.isHidden })"
        class="checkbox-input"
        type="checkbox"
        v-model="form.isHidden"
      />
      Hide
    </label>
  </div>
</template>

<script>
import FormsMixin from "./mixins/forms.mixin";

export default {
  name: "LibraryListItemControls",

  mixins: [FormsMixin],

  props: { playlistId: String },

  data: () => ({
    errorMessage: null,
    elemId: "library",
    requiredFields: ["isSubscriberPL", "isHidden"],
    selectedVideo: null
  }),

  methods: {}
};
</script>

<style lang="scss">
.list-item--controls {
  flex-shrink: 0;

  &,
  .admin-tools {
    @include flex;
  }

  .admin-tools {
    color: $grey;
    font-size: $xs;
    line-height: initial;
    margin: 0 $xxs 0 0;

    .checkbox-input {
      height: auto;
      margin: 0 $xxs;
    }
  }
}

/* Responsive | 600px */
@media screen and (max-width: 600px) {
  .list-item--controls {
    .admin-tools {
      flex-shrink: 0;
      margin: 0;
    }
  }
}
</style>
