<template>
  <article
    class="list-item list-item--playlist"
    @click.prevent="!selected && toggleExpanded()"
    :aria-expanded="selected"
  >
    <header class="title justify">
      <h6 class="title-text" @click.stop="toggleExpanded">
        <span class="grow">
          <i
            :class="{
              'fab fa-youtube': header || !form.isHidden,
              'fas fa-eye-slash': !header && form.isHidden,
              'grey-light--text': !selected,
              'success--text': !selected && form.isSubscriberPL,
              'error--text': selectedVideo,
              link: selected && !selectedVideo
            }"
          />
          &nbsp;
          {{ title }}
        </span>

        <i
          class="fas scale-in-bounce"
          :class="{ 'fa-times-circle error--text': selected }"
          role="button"
          @click.stop="$emit('deselect-playlist', playlistId)"
        />
      </h6>

      <library-list-item-controls
        v-if="!header && showAdminControls"
        :form-data="formData"
        :playlist-id="playlistId"
        @change="appendAndEmit"
      />
    </header>

    <blockquote
      class="subscribers-stamp"
      v-if="selected && form.isSubscriberPL"
    >
      <i class="fas fa-credit-card"></i>
      Subscribers Only
    </blockquote>

    <div
      :class="{ 'slide-down-fade-in': selected, 'list--videos': true }"
      v-if="selected"
      role="menu"
      :aria-expanded="selected"
    >
      <!-- Video Player -->
      <iframe
        v-if="selectedVideo"
        :src="`https://www.youtube.com/embed/${selectedVideo.videoId}`"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        class="selected-video route-content"
        frameborder="0"
        height="400"
        width="100%"
      />

      <!-- Video Thumbnails (videos in playlist) -->
      <tile-list-item
        role="menuitem"
        v-for="video in videosList"
        :action-text="videoThumbnailText(video.videoId)"
        :background-image="video.thumbnail"
        class="list-item--video"
        :class="{ active: selectedVideoId === video.videoId }"
        :href="video.videoLink"
        :key="video.videoId"
        :selected="selectedVideoId === video.videoId"
        :title="video.title"
        button
        @list-item-click="selectVideo(video)"
      />
    </div>
  </article>
</template>

<script>
import LibraryListItemControls from "./LibraryListItem.Controls.vue";
import FormsMixin from "./mixins/forms.mixin";
import TileListItem from "./TileListItem.vue";

export default {
  name: "LibraryListItem",

  mixins: [FormsMixin],

  components: { TileListItem, LibraryListItemControls },

  props: {
    header: Boolean,
    selected: Boolean,
    showAdminControls: Boolean,
    videosList: Array
  },

  data() {
    return {
      elemId: "library",
      errorMessage: null,
      requiredFields: [
        "id",
        "name",
        "playlistId",
        "isHidden",
        "isSubscriberPL"
      ],
      selectedVideo: null
    };
  },

  computed: {
    playlistId() {
      return (this.formData || { playlistId: null }).playlistId;
    },

    selectedVideoId() {
      return (this.selectedVideo || { videoId: null }).videoId;
    },

    title() {
      return (this.formData || { name: "Playlist Name" }).name;
    }
  },

  mounted() {
    if (this.formData) {
      this.hydrate(this.formData);
    }
  },

  methods: {
    initializeForm() {
      this.form = {
        id: null,
        name: this.title || "Playlist Name",
        playlistId: this.playlistId,
        isSubscriberPL: false,
        isHidden: true
      };
    },

    selectVideo(video) {
      if (video && video.videoId !== this.selectedVideoId) {
        this.selectedVideo = video;
      }
    },

    toggleExpanded() {
      const action = this.selected ? "deselect" : "select";
      return this.$emit(`${action}-playlist`, this.playlist);
    },

    videoThumbnailText(id) {
      const base = "Watch Video";
      return this.selectedVideoId === id ? `Selected` : base;
    }
  }
};
</script>

<style lang="scss" src="./LibraryListItem.scss"></style>
