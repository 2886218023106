<template>
  <section id="library" class="route">
    <loader v-if="loading" :loading-message="loadingMessage" />

    <h1 class="title">
      Video
      <hr class="divider divider--vertical" />
      <span class="accent--text">Library</span>
      <hr class="divider divider--vertical" />
    </h1>

    <div class="flex flex--centered">
      <custom-legend v-if="isAdminUser" :items="legend" />
    </div>

    <div class="route-content list list--playlists" role="menu">
      <library-list-item
        class="list-item--header"
        title="Playlist Name"
        :selected="false"
        :header="true"
      />

      <library-list-item
        v-for="p in playlists"
        role="menu-item"
        :key="p.playlistId"
        :form-data="p"
        :selected="p.playlistId === selectedPlaylist"
        :videos-list="videosList"
        @deselect-playlist="deselectPlaylist(p.playlistId)"
        @select-playlist="selectPlaylist(p.playlistId)"
      />

      <p
        v-if="!playlists.length"
        class="list-item list-item--centered list-item--label wide"
      >
        <em class="grey--text">No playlists found. Check again later!</em>
      </p>
    </div>

    <modal-component
      v-if="isAdminUser"
      title="Manage YouTube Playlists"
      confirmation-text="Update All"
      :visible="managePlaylists"
      @confirm="updatePlaylists"
      @close="managePlaylists = false"
    >
      <!-- Library Settings Content -->
      <library-playlist-settings @change="onSettingsChanged" />

      <!-- Loading  message -->
      <em v-if="loading" class="list-item loading-message">
        {{ loadingMessage }}
      </em>
    </modal-component>
  </section>
</template>

<script>
import CustomLegend from "../components/CustomLegend.vue";
import LibraryListItem from "../components/LibraryListItem.vue";
import Loader from "../components/Loader.vue";
import PermissionsMixin from "../components/mixins/permissions.mixin";
import SubrouteManagerMixin from "../components/mixins/subroute-manager.mixin";
import ModalComponent from "../components/ModalComponent.vue";
import {
  getSavedPlaylists,
  getVideo,
  updateMultiplePlaylists
} from "../models/library";
import state from "../state";
import LibraryPlaylistSettings from "./Library.PlaylistSettings.vue";

export default {
  name: "Library",

  components: {
    CustomLegend,
    LibraryListItem,
    LibraryPlaylistSettings,
    Loader,
    ModalComponent
  },

  mixins: [PermissionsMixin, SubrouteManagerMixin],

  data: () => ({
    errorMessage: null,
    loading: true,
    managePlaylists: false,
    playlists: [],
    selectedPlaylist: null,
    selectedVideo: null,
    settingsPL: null,
    videosList: []
  }),

  computed: {
    legend() {
      return [
        {
          icon: `fab fa-youtube grey--text`,
          text: `Accessible video`
        },
        {
          icon: `fas fa-eye-slash grey--text`,
          text: `Hidden video`
        },
        {
          icon: `fas fa-square success--text`,
          text: `Subscriber video`
        },
        {
          icon: `fas fa-square link`,
          text: `Playlist is selected`
        },
        {
          icon: `fas fa-square error--text`,
          text: `Playlist Video is selected`
        }
      ];
    },

    subroutes() {
      return this.isAdminUser
        ? [
            {
              name: "Library",
              text: "Manage Videos",
              icon: "fas fa-cog grey--text",
              click: () => (this.managePlaylists = true)
            }
          ]
        : [];
    }
  },

  async mounted() {
    this.attachToState();
    this.broadcastSubroutes();
    await this.reloadSavedPlaylists();
  },

  methods: {
    async selectPlaylist(id) {
      if (id !== this.selectedPlaylist) {
        const matchId = ({ playlistId }) => playlistId === id;
        const { name } = this.playlists.find(matchId);

        try {
          this.startLoading(`Loading ${name} ...`);
          const videosList = await getVideo(id);
          this.videosList = videosList;
          this.selectedPlaylist = id;
          this.stopLoading();
        } catch (error) {
          this.onViewError(error.message || error);
        }
      }
    },

    deselectPlaylist() {
      this.videosList = [];
      this.selectedPlaylist = null;
      this.loading = false;
    },

    /**
     * @override React to global app state update
     * @param {object} state updated app state
     * @param {object} state.user Logged-in user (if present)
     * @param {object} state.libraryPlaylists Video Library (past events/classes)
     */
    onAppState({ user, libraryPlaylists }) {
      this.activeUser = user;
      this.playlists = libraryPlaylists;
    },

    onSettingsChanged({ playlists }) {
      if (!this.isAdminUser) return;
      this.settingsPL = playlists;
    },

    async reloadSavedPlaylists(msg = "Fetching Playlists ... ") {
      this.startLoading(msg);
      const isSubscriber = Number(this.isSubscriber === true);
      try {
        const playlists = await getSavedPlaylists(isSubscriber);
        state.libraryPlaylists(playlists);
        this.stopLoading();
      } catch (error) {
        this.onViewError(error.message || error);
      }
    },

    async updatePlaylists() {
      this.startLoading("Updating Playlists ... ");
      try {
        await updateMultiplePlaylists({ playlists: this.settingsPL });
        await this.reloadSavedPlaylists("Reloading Playlists ...");
        // hide modal
        this.managePlaylists = false;
      } catch (error) {
        this.onViewError(error.message || error);
      }
    }
  }
};
</script>

<style lang="scss" src="./Library.scss"></style>
