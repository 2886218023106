<template>
  <section class="list list--playlists list--playlist-settings" role="menu">
    <hr class="divider divider-md" />
    <p class="instructions">
      Show or hide playlists to certain users here. Save your changes with the
      <b class="h6 success-dark--text">UPDATE ALL</b> button.
    </p>

    <hr class="divider divider--tiny grey-light" />

    <library-list-item
      class="list-item--header"
      title="Playlist Name"
      :selected="false"
      :header="true"
    />

    <library-list-item
      v-for="playlist in form.playlists"
      role="menu-item"
      show-admin-controls
      :form-data="playlist"
      :key="playlist.playlistId"
      @change="onPlaylistChanged"
    />

    <em v-if="loading" class="slide-in-right loading-message">
      <b>{{ loadingMessage }}</b>
    </em>

    <p
      v-if="!loading && !form.playlists.length"
      class="list-item list-item--centered list-item--label wide"
    >
      <em class="grey--text">No playlists found. Check again later!</em>
    </p>
  </section>
</template>

<script>
import LibraryListItem from "../components/LibraryListItem.vue";
import FormsMixin from "../components/mixins/forms.mixin";
import PermissionsMixin from "../components/mixins/permissions.mixin";
import { getPlaylistLibrary } from "../models/library";

export default {
  name: "LibraryPlaylistSettings",

  components: { LibraryListItem },

  mixins: [PermissionsMixin, FormsMixin],

  data: () => ({
    loading: true,
    form: { playlists: [] },
    requiredFields: ["playlists"]
  }),

  async mounted() {
    if (this.isAdminUser) {
      this.startLoading("Fetching Org. playlists ... ");
      const playlists = await getPlaylistLibrary();
      this.appendAndEmit({ playlists });
    }
    this.stopLoading();
  },

  methods: {
    onPlaylistChanged(playlist) {
      const findTarget = ({ playlistId }) => playlistId === playlist.playlistId;
      const target = this.form.playlists.findIndex(findTarget);
      const swapTarget = (p, i) => (i === target ? playlist : p);
      this.appendAndEmit({ playlists: this.form.playlists.map(swapTarget) });
    }
  }
};
</script>

<style lang="scss">
.list--playlist-settings {
  .instructions {
    padding: $xs;
  }

  .loading-message {
    @include flex;

    &::before {
      @include after-spinner($link, $sm);
      margin-right: $xxs;
    }
  }
}
</style>
