import { apiConfig } from "../network/index";
import Playlist from "./library.model";

/**
 * Get a video from a YouTube playlist
 * @param {string} playlist YouTube playlist Id
 */
export async function getVideo(playlist) {
  const { videos } = await apiConfig.getVideo({ playlist });
  return videos;
}

/**
 * Admin Get list of all (Organization) channel playlists
 * @returns {Promise<object[]>} List of playlists
 */
export async function getPlaylistLibrary() {
  const [libraryPlaylists, { playlists }] = await Promise.all([
    getSavedPlaylists(1),
    apiConfig.getPlaylistLibrary()
  ]);

  return playlists.map(pl => {
    const matchSaved = ({ playlistId }) => playlistId === pl.playlistId;
    const publicPL = libraryPlaylists.find(matchSaved) || {};
    return Playlist({ ...pl, ...publicPL });
  });
}

/**
 * Get list of playlists that are visible to (non-Admin context) user
 * @param {number} subscriber Requester is a subscriber (`0` or `1`)
 * @returns {Promise<object[]>} List of playlists
 */
export async function getSavedPlaylists(subscriber = 0) {
  const { data } = await apiConfig.getSavedPlaylists({ subscriber });
  return data.map(Playlist);
}

/**
 * Update multiple playlists at once
 * @param {object} params Request params
 * @param {object[]} params.playlists List of `playlists` to update
 * @returns {Promise<{ message: string }>} `OK` or `error message`
 */
export async function updateMultiplePlaylists({ playlists }) {
  const newPlaylists = [];
  const updatePromises = [];

  playlists.forEach(pl => {
    const playlist = Playlist(pl);
    const playlistId = playlist.id;
    // Add single update promise, or batch new playlist for creation
    if (playlistId) {
      updatePromises.push(updateSinglePlaylist({ playlist, playlistId }));
    } else newPlaylists.push(playlist);
  });

  // Add batched playlists for creation (if any)
  if (newPlaylists.length > 0) {
    updatePromises.push(apiConfig.createPlaylists({ playlists: newPlaylists }));
  }

  // Run updates
  return await Promise.all(updatePromises);
}

/**
 * Update the visibility of multiple items in a YouTube playlist
 * @param {object} params Request params
 * @param {object[]} params.playlist Playlist content
 * @param {string} params.playlistId Playlist Id
 * @returns {Promise<{ message: string }>} `OK` or `error message`
 */
export async function updateSinglePlaylist(params) {
  const { message, data, error } = await apiConfig.updatePlaylist(params);
  return message === "success"
    ? Promise.resolve({ message })
    : Promise.reject({ message, data, error });
}
