/**
 * 
 * @param {object} data Source data
 * @returns {{
 * id: number|null|undefined,
 * isHidden: boolean,
 * isSubscriberPL: boolean,
 * name: string,
 * playlistId: string,
}}
 */
export default function Playlist(data) {
  const playlist = {
    isHidden: typeof data.isHidden !== "boolean" || data.isHidden,
    isSubscriberPL: data.isSubscriberPL || false,
    name: data.name || null,
    playlistId: data.playlistId || null
  };

  if (data.id) playlist.id = data.id;

  return playlist;
}
